@font-face {
    font-family: 'Roboto Regular';
    src: local('Roboto Regular'), url("../fonts/Roboto-Regular.ttf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url("../fonts/Roboto-Light.ttf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Bold';
    src: local('Roboto Bold'), url("../fonts/Roboto-Bold.ttf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Black';
    src: local('Roboto Black'), url("../fonts/Roboto-Black.ttf") format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "SegoeBL";
    src: url("../fonts/segoe-ui-bold.ttf") format("truetype");
}

@font-face {
    font-family: "SegoeRegular";
    src: url("../fonts/segoe-ui.ttf") format("truetype");
}

.title {
    font-family: "SegoeBL", serif;
    color: white;
    font-size: 3.7vh;
    font-size: 3.7svh;
}

.text{
    color: white;
    font-family: "SegoeRegular", "serif";
    font-size: 2.1vh;
    font-size: 2.1svh;
}

.bold {
    font-family: "SegoeBL", serif;
}

.question{
    font-size: 3.3vh;
    font-size: 3.3svh;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}
