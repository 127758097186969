html {
    height: 100%;
    width: 100%;
}

body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0;
}

a {
    color: black;
}

#root {
    height: 100%;
    width: 100%;
}

.degrade-circle {
    position: absolute;
    margin-top: 24vh;
    margin-top: 24svh;
    height: 112vh;
    height: 112svh;
    width: 112vh;
    width: 112svh;
    border-radius: 50%;
    background: linear-gradient(270deg, #CA36F4 0%, #802AC7 46.46%, #6DDAFB 93.3%);
    filter: blur(4vh);
    filter: blur(4svh);
}

.screen {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    background-color: #181a20;
    font-family: "Roboto Regular", serif;
}


.screen-content {
    height: 100%;
    width: 37.54vh;
    width: 37.54svh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.screen-sub-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel {
    margin-top: 1.6vh;
    margin-top: 1.6svh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.more-information-container {
    width: 95%;
}

.eterfy-logo {
    margin-top: 18vh;
    margin-top: 18svh;
    width: 25vh;
    width: 25svh;
    height: 10vh;
    height: 10svh;
    background-image: url("../images/eterfy_logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
}

.footer {
    width: 22.18vh;
    width: 22.18svh;
    height: 2.83vh;
    height: 2.83svh;
    margin-bottom: 4vh;
    margin-bottom: 4svh;
    background-image: url("../images/powered_by_etermax.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.main-content{
    margin-top: 2.4vh;
    margin-top: 2.4svh;
    width: 40.5vh;
    width: 40.5svh;
    height: 17vh;
    height: 17svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.main-sub-content{
    width: 31.7vh;
    width: 31.7svh;
}

.inline {
    display: inline;
}

.question{
    margin-top: 3.57vh;
    margin-top: 3.57svh;
}

.links-container{
    margin-top: 2.4vh;
    margin-top: 2.4svh;
    width: 31.7vh;
    width: 31.7svh;
    height: 15.4vh;
    height: 15.4svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

@media (orientation: landscape) {
    .degrade-circle {
        height: 117.56vh;
        height: 117.56svh;
        width: 122vw;
        width: 122svw;
    }
}
